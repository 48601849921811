import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import * as CryptoJS from "crypto-js";
import './loginForm.css';
// material
import {
  Alert,
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import InputLabel from '@mui/material/InputLabel';
import { setUserSession } from '../../../utils/Auth';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [verifyOTP, setverifyOTP] = useState(true);
  const [verifysentOTP, setverifysentOTP] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setpasswordError] = useState('');
  const [userInputt, setuserInputt] = useState('');
  let userInput = ''
  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    email: Yup.string().required('Email/UserId/Phone is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      if(values.email === 'VendorReg' && values.password === 'Reg@1111') {
        window.location.replace("https://plsuatvp.europassistance.in/vendor-register")
      }
      let user;
      let isEmail = false;
      let isNumber = false;

      let numberType = 0;
      numberType = Number(values.email)

      isEmail = values.email.includes("@");

      // console.log(numberType, 'numberType')
      // console.log(typeof(values.email), 'type')
      // console.log(values.email.length, 'length')
      
      if(values.email.length === 10) {
        // console.log("Came hrere")
        isNumber = Number.isInteger(numberType)
        // console.log(isNumber, 'isNumber')
      }

      // console.log(isEmail, 'isEmail')
      // console.log(isNumber, 'isNumber')

      if(isEmail) {
        user = { email: values.email, password: values.password };  
        userInput = 'email';
        setuserInputt('email')
      }
      else if(isNumber) {
        user = { phone: values.email, password: values.password };  
        userInput = 'phone';
        setuserInputt('phone')
      }
      else {
        user = { userId: values.email, password: values.password };  
        userInput = 'userId';
        setuserInputt('userId')
      }

      console.log(user, 'user')

      const encuser = {
        "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(user), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
      }
      console.log(encuser, 'encrypted data')
      fetch(`${process.env.REACT_APP_BASEURL}/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(encuser)
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, 'data')
          setpasswordError('');
          if (data.code === 200) {
            setSuccessMessage(data.msg);
            setTimeout(() => {
              setverifysentOTP(true);
              setverifyOTP(false);
              // navigate('/otp', { replace: true });
            }, 100);

            // setTimeout(() => {
            //   setSuccessMessage('');
            // }, 3000);

            // if (data.data) {
            //   setUserSession(
            //     data.data.userData.email,
            //     data.data.userData.firstName,
            //     data.data.userData.lastName,
            //     data.data.userData.phoneNumber,
            //     data.data.AutherisationToken
            //   );
            //   navigate('/dashboard', { replace: true });
            // } else {
            //   setpasswordError(data.msg);
            // }
          } else {
            setpasswordError(data.msg);
          }
        });

      // let result = fetch('https://localhost:3000/auth/users/login_test', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Accept: 'application/json'
      //   },
      //   body: JSON.stringify(user)
      // });
      // result = result.json();
      // console.log(result, 'result');
      // localStorage.setItem('user-data', JSON.stringify(result));
      // console.log(values, 'values');
    }
  });

  const handleSendOTP = () => {
    console.log(userInput, 'userInput')
    console.log(userInputt, 'userInputt')
    let otp = {};
    if(userInputt === 'email') {
      otp = { email: values.email, otp: values.otp };
    }
    else if(userInputt === 'phone') {
      otp = { phone: values.email, otp: values.otp };
    }
    else if(userInputt === 'userId') {
      otp = { userId: values.email, otp: values.otp };
    }
    console.log(otp, 'consume testing')
    const encuser = {
      "encryptedData": CryptoJS.AES.encrypt(JSON.stringify(otp), '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR').toString()
    }
    console.log(otp, 'otp value');
    fetch(`${process.env.REACT_APP_BASEURL}/api/users/consumeLogInOtpEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(encuser)
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, 'after login data');
        if (data.code === 200) {
          setSuccessMessage(data.msg);
          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          data.data = decryptedData;
          if (data.data) {


            const loginData = data.data
            const token = loginData.AutherisationToken;
            const userId  = loginData.userData._id;
            const cPortalId = localStorage.getItem('cPortalId')
            console.log(userId, 'id test for error')
            fetch(`${process.env.REACT_APP_BASEURL}/api/users/listAllActiveUser?userId=${userId}`, {
              method: 'GET',
              headers: {
                Authorization: token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
              },
            })
              .then((res) => res.json())
              .then((data) => {
                if (data.code === 200) {
                  const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
                  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                  data.data = decryptedData;
                  console.log(data.data, 'current user data')                  
                  const userId = data.data[0].userName
                  if (data.data[0].applicationData.length === 1) {
                    const clientsData = data.data[0].clientData
                    const item = data.data[0].applicationData[0]
                    const appName = item.name.description
                    if (clientsData.length > 1) {
                      let userRole = ''
                      if (loginData.userRole.length) {
                        userRole = loginData.userRole[0].RolesDAta[0].name
                      }
                      else {
                        userRole = 'User'
                      }
                      setUserSession(
                        loginData.userData.email,
                        loginData.userData.firstName,
                        loginData.userData.lastName,
                        loginData.userData.phoneNumber,
                        loginData.AutherisationToken,
                        loginData.userData._id,
                        loginData.userData.cPortalId,
                        userRole,
                        userId
                      );
                      navigate('/dashboard', { replace: true });
                    }
                    else {
                      const Clients = [];
                      const appRoleData = data.data[0].userapplicationclinetrolesData
                      let appRole = ''
                      if(appRoleData.length === 1) {
                        appRole = appRoleData[0].role
                      }

                      clientsData.forEach(element => {
                        Clients.push({ client: element.actualName, role: appRole, uuid: element.uuid })
                      });
                      // console.log(Clients, 'all clients for test')
                      // goForAppTest(item)
                      // console.log(Clients[0], 'present client')
                      const clientIDE = Clients[0].client
                      const Euuid = Clients[0].uuid
                      // console.log(Euuid, 'Euuid for test')
                      const currenctRole = Clients[0].role
                      const role = currenctRole
                      const user = {
                        "clientId": item._id,
                        "redirectUri": item.applicationRedirectUrl,
                        "userId": userId
                      }
                      const encuser = {
                        encryptedData: CryptoJS.AES.encrypt(
                          JSON.stringify(user),
                          '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
                        ).toString()
                      };
                      // console.log(user, 'user ata')
                      fetch(`${process.env.REACT_APP_BASEURL}/api/auth/oauth2/grantToken`, {
                        method: 'POST',
                        headers: {
                          Authorization: token,
                          'Content-Type': 'application/json',
                          Accept: 'application/json'
                        },
                        body: JSON.stringify(encuser)
                      })
                        .then((res) => res.json())
                        .then((data) => {
                          const bytes = CryptoJS.AES.decrypt(data.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
                          const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                          data.data = decryptedData;
                          const cportalId = loginData.userData.cPortalId
                          if (data.data.oAuthToken) {
                            window.location.replace(`${item.applicationRedirectUrl}?appName=${appName}&clientIDE=${clientIDE}&clientID=${item._id}&role=${role}&oAuthtoken=${data.data.oAuthToken}&token=${token}&userId=${loginData.userData.cPortalId}&redirectURL=${item.applicationRedirectUrl}&log=true&uuid=${Euuid}&_id=${loginData.userData._id}&isActive=true&email=${loginData.userData.email}&userName=${userId}&firstName=${loginData.userData.firstName}&lastName=${loginData.userData.lastName}&phoneNumber=${loginData.userData.phoneNumber}`)
                          }
                        });

                    }


                  }
                  else {
                    let userRole = ''
                    if (loginData.userRole.length) {
                      userRole = loginData.userRole[0].RolesDAta[0].name
                    }
                    else {
                      userRole = 'User'
                    }
                    setUserSession(
                      loginData.userData.email,
                      loginData.userData.firstName,
                      loginData.userData.lastName,
                      loginData.userData.phoneNumber,
                      loginData.AutherisationToken,
                      loginData.userData._id,
                      loginData.userData.cPortalId,
                      userRole,
                      userId
                    );
                    navigate('/dashboard', { replace: true });
                  }
                }
              });













            // let userRole = ''
            // if (data.data.userRole.length) {
            //   userRole = data.data.userRole[0].RolesDAta[0].name
            // }
            // else {
            //   userRole = 'User'
            // }
            // setUserSession(
            //   data.data.userData.email,
            //   data.data.userData.firstName,
            //   data.data.userData.lastName,
            //   data.data.userData.phoneNumber,
            //   data.data.AutherisationToken,
            //   data.data.userData._id,
            //   userRole
            // );
            // navigate('/dashboard', { replace: true });
          } else {
            setpasswordError(data.msg);
          }
        } else {
          setpasswordError(data.msg);
        }
      });
  };

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  const testCheck = (e) => {
    window.open("http://localhost:3002/pages/login/logout");
  }

  return (
    <FormikProvider value={formik}>
      {verifysentOTP ? (
        <Stack spacing={3}>
          <InputLabel style={{color: 'rgb(199 199 199)'}}>OTP</InputLabel>
          <TextField
            fullWidth
            autoComplete="off"
            type="otp"
            label=""
            name="otp"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
            className='inputLogin'
            style={{marginTop: '5px'}}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSendOTP}
            style={{backgroundColor: '#e5042c'}}
          >
            Submit OTP
          </LoadingButton>
        </Stack>
      ) : (
        ''
      )}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {verifyOTP ? (
          <Stack spacing={3}>
            <InputLabel style={{color: 'rgb(199 199 199)'}}>Email/UserId/Phone</InputLabel>
            <TextField              
              fullWidth
              autoComplete="off"
              type="email"
              label=""
              name="email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              // helperText={touched.email && errors.email}              
              className='inputLogin'
              style={{marginTop: '5px'}}
            />
            <span style={{color: 'red', fontSize:'12px', marginTop: '5px'}}>{touched.email && errors.email}</span>
            <InputLabel style={{color: 'rgb(199 199 199)'}}>Password</InputLabel>
            <TextField
              fullWidth
              autoComplete="off"
              type={showPassword ? 'text' : 'password'}
              label=""
              name="password"
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              // helperText={touched.password && errors.password}
              className='inputLogin'
              style={{marginTop: '5px'}}
            />
            <span style={{color: 'red', fontSize:'12px', marginTop: '5px'}}>{touched.password && errors.password}</span>
          </Stack>
        ) : (
          ''
        )}
        {verifyOTP ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            {/* <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} style={{color: 'white'}}/>}
              label="Remember me" style={{color: 'white', borderRadius: '4px'}}/> */}
            <div/>
            <Link component={RouterLink} variant="subtitle2" to="/forgetpassword" style={{color: 'white'}}>
              Forgot password?
            </Link>
          </Stack>
        ) : (
          ''
        )}
        {verifyOTP ? (
          <LoadingButton fullWidth size="large" type="submit" variant="contained" style={{backgroundColor: '#e5042c'}}>
            Login
          </LoadingButton>
        ) : (
          ''
        )}
        {/* {verifyOTP ? (
          <LoadingButton fullWidth size="large" type="submit" variant="contained"  onClick={testCheck}>
            Login
          </LoadingButton>
        ) : (
          ''
        )} */}
      </Form>
      <br />
      {passwordError ? <Alert severity="success">{passwordError}</Alert> : ''}
      {successMessage ? <Alert severity="success">{successMessage}</Alert> : ''}
    </FormikProvider>
  );
}
